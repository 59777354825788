/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
button mat-spinner {
  width: 100% !important;
  height: 100% !important;
  top: calc(50% - 10px);
  position: absolute !important;
  left: 0;
  right: 0;
}

table button.mdc-fab {
  width: 35px;
  height: 35px;
  min-height: 35px;
  min-width: 35px;
}
table .mdc-data-table__cell {
  padding: 0 10px;
}

.table-container {
  position: relative;
}
.table-container mat-spinner {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
}

button mat-spinner {
  width: 100% !important;
  height: 100% !important;
  top: calc(50% - 10px);
  position: absolute !important;
  left: 0;
  right: 0;
}

table tr .td-detail {
  overflow: hidden;
  display: flex;
}
table tr.tr-detail-row {
  height: 0 !important;
}
table tr.tr-detail-row td {
  border: 0;
}
table tr.tr-detail-row:not(.td-expanded-row):hover {
  background: whitesmoke;
}
table tr.tr-detail-row:not(.td-expanded-row):active {
  background: #efefef;
}
table tr td {
  border-bottom-width: 0;
}
table tr td .td-element-description {
  padding: 16px;
}
table button.mdc-fab--mini {
  width: 30px;
  height: 30px;
  min-height: 30px;
  min-width: 30px;
}
table button.mdc-fab--mini mat-icon {
  width: 20px;
  min-width: 20px;
  height: 20px;
  min-height: 20px;
}

.mat-drawer-container fuse-alert .fuse-alert-container, app-users-permissions fuse-alert .fuse-alert-container, app-users-invites-drawer fuse-alert .fuse-alert-container {
  padding: 10px 16px !important;
  display: flex !important;
  align-items: center;
}
.mat-drawer-container fuse-alert .fuse-alert-container .fuse-alert-message, app-users-permissions fuse-alert .fuse-alert-container .fuse-alert-message, app-users-invites-drawer fuse-alert .fuse-alert-container .fuse-alert-message {
  display: flex !important;
  align-items: center;
}
.mat-drawer-container fuse-alert .fuse-alert-container button[fuse-alert-close], app-users-permissions fuse-alert .fuse-alert-container button[fuse-alert-close], app-users-invites-drawer fuse-alert .fuse-alert-container button[fuse-alert-close] {
  max-height: 16px;
}
.mat-drawer-container fuse-alert .fuse-alert-container button[fuse-alert-close] mat-icon, app-users-permissions fuse-alert .fuse-alert-container button[fuse-alert-close] mat-icon, app-users-invites-drawer fuse-alert .fuse-alert-container button[fuse-alert-close] mat-icon {
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  font-size: 16px;
}

.form-container {
  height: 100%;
}

@media (min-width: 960px) {
  .mat-drawer {
    min-width: 500px !important;
  }
}
.mat-drawer {
  min-width: 100%;
}

mat-drawer-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
}
mat-drawer-content .filters .min-width-select {
  min-width: 200px;
}
mat-drawer-content .filters .mat-mdc-form-field-subscript-wrapper {
  display: none;
}

input[disabled] {
  opacity: 0;
}

mat-form-field.reset-css .mat-mdc-text-field-wrapper {
  border: 0 !important;
}
mat-form-field.reset-css .mat-mdc-form-field-infix {
  height: 0 !important;
  min-height: 0 !important;
}
mat-form-field.reset-css .mat-mdc-form-field-subscript-wrapper {
  height: 0 !important;
}

.mat-badge-content {
  background-color: #dc2626 !important;
  color: #ffffff !important;
}

.main-z-index {
  z-index: 9999999999;
}

#body-content {
  margin-top: 64px;
}

.v-align-top {
  vertical-align: top;
}

.org-dropdown-title {
  display: flex;
  justify-content: right;
}

/* Ajuste para garantir que os nomes de tamanhos sejam exibidos corretamente */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="12px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="14px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="16px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="18px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="20px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="24px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="32px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="32px"]::before {
  content: attr(data-value);
}

.ql-table {
  width: 100%;
  border-collapse: collapse;
}

.ql-table th, .ql-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

.mat-mdc-slide-toggle {
  --mdc-switch-unselected-handle-color: #0f172a !important;
  --mdc-switch-unselected-hover-handle-color: #0f172a !important;
  --mdc-switch-unselected-focus-handle-color: #0f172a !important;
  --mdc-switch-unselected-pressed-handle-color: #0f172a !important;
  --mdc-switch-selected-hover-state-layer-color: rgb(21 128 61 / var(--tw-text-opacity)) !important;
  --mdc-switch-selected-handle-color: rgb(21 128 61 / var(--tw-text-opacity)) !important;
  --mdc-switch-selected-hover-handle-color: rgb(21 128 61 / var(--tw-text-opacity)) !important;
  --mdc-switch-selected-focus-handle-color: rgb(21 128 61 / var(--tw-text-opacity)) !important;
  --mdc-switch-selected-focus-state-layer-color: rgb(21 128 61 / var(--tw-text-opacity)) !important;
  --mdc-switch-selected-pressed-state-layer-color: rgb(21 128 61 / var(--tw-text-opacity)) !important;
  --mdc-switch-selected-pressed-handle-color: rgb(21 128 61 / var(--tw-text-opacity)) !important;
}

.hamburger-menu {
  visibility: hidden;
}
@media (max-width: 959px) {
  .hamburger-menu {
    visibility: visible;
  }
}

.timepicker-button {
  display: inline-block;
  height: 36px;
  min-width: 88px;
  line-height: 36px;
  border: 12px;
  border-radius: 2px;
  background-color: transparent;
  text-align: center;
  transition: 450ms cubic-bezier(0.23, 1, 0.32, 1);
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  cursor: pointer;
  outline: 0;
  color: var(--button-color);
}
.timepicker-button span {
  font-family: var(--primary-font-family);
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  padding-left: 16px;
  padding-right: 16px;
}
.timepicker-button:hover {
  background-color: rgba(153, 153, 153, 0.2);
}

.fieldset-style {
  border: 1px solid #d3d3d3;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
}

.legend-style {
  font-weight: bold;
  margin-left: 10px;
  font-size: 1em;
}

.org-container {
  border: 2px solid #ccc; /* Light gray border */
  border-radius: 8px; /* Rounded corners */
  padding: 10px; /* Spacing inside the border */
  display: inline-block; /* Adjusts to content width */
  background-color: #f9f9f9; /* Light background color */
}

.org-link {
  display: flex; /* Align icon and text */
  align-items: center; /* Vertically center content */
  color: #333; /* Dark text color */
  font-weight: bold; /* Bold text for organization name */
  text-decoration: none; /* Remove underline on hover */
  gap: 8px; /* Space between icon and text */
}

.org-icon {
  color: var(--fuse-primary) !important;
  font-size: 20px; /* Adjust icon size */
}

.selected-item {
  background-color: #d9f0ff !important;
  border-left: 4px solid var(--fuse-primary) !important;
}
.selected-item span {
  font-weight: bold !important;
}